import HeaderComponent from "../components/HeaderComponent";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import notFoundBanner from "../images/not-found-banner.svg"
import notFoundDark from "../images/not-found-banner-dark.svg"
import { Link } from "react-router-dom";

export default function NotFound(){
    return(
        <div className="not-found-page-wrapper">
            <header>
                <SecondaryHeader />
            </header>
            <main>
                <div className="page-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                            <div className="page-content">
                            <h1>404</h1>
                            <div className="description">
                                <h2>You need a hall pass!</h2>
                                <p>This page you are looking for doesn’t exist or has been moved</p>
                            </div>
                            <div className="back-button">
                                <Link to={"/"}>Return To Home Room</Link>
                            </div>
                        </div>
                            </div>
                            <div className="col-md-6 align-self-center">
                                <div className="page-banner">
                                    <img src={notFoundBanner} alt="banner" className="light-theme-img"/>
                                    <img src={notFoundDark} alt="banner" className="dark-theme-img"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </main>
            <footer>
                <SecondaryFooter />
            </footer>
        </div>
    )
}