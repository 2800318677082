import {Link} from 'react-router-dom'
import facebook from "../images/icons/facebook-icon.svg"
import xIcon from "../images/icons/x-icon.svg"
import linkedin from "../images/icons/linkedin-icon.svg"
import instagram from "../images/icons/instagram-icon.svg"

export default function SecondaryFooter() {
  return (
    <>
      <div className="footer-bottom-bar">
        <div className=" large1">
          <div className="row">
            <div className="col-md-8">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={require('../images/icons/systemicon.png')}
                    alt="logo"
                    className="logo"
                  />
                </Link>
                <ul className="bottom-menu">
                  <li>
                    <Link to="/status">Status</Link>
                  </li>
                  <li>
                    <Link to="/terms-services">Terms</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="social-items-wrapper">
                <ul className='bottom-menu'>
                    <li>
                        <Link to={"#"}>
                            <img src={facebook} alt='social-icon'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={"#"}>
                            <img src={xIcon} alt='social-icon'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={"#"}>
                            <img src={linkedin} alt='social-icon'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={"#"}>
                            <img src={instagram} alt='social-icon'/>
                        </Link>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
