import HeaderComponent from "../components/HeaderComponent";
import arrowRightBlack from "../images/icons/arrow-right-black.svg";
import chevronRight from "../images/icons/chevron-right.svg";
import logo from "../images/icons/logo.svg";
import login from "../images/icons/login.svg";
import docs from "../images/icons/docs.svg";
import contact from "../images/icons/contact.svg";
import wifi from "../images/icons/wifi.svg";
import homeBanner from "../images/home-page-banner.svg";
import homeBannerDark from "../images/home-page-banner-dark.svg";
import SecondaryFooter from "../components/SecondaryFooter";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <>
      <HeaderComponent />
      <div className="home-page">
        <div className="container">
          <div className=" home-page-content">
            <div className="page-header">
              <div className="row align-self-center">
                <div className="col-md-7 ">
                  <div className="explore-btn">
                    {/* <Link to={"#"}>
                      Explore our platform
                      <img src={arrowRightBlack} alt="arrow icon" />
                    </Link> */}
                    <h1>Welcome <br />
                    <span>
                    To Our Platform!
                    </span>
                    </h1>
                  </div>
                </div>
                <div className="col-md-5 ">
                  <div className="login-options">
                    <div className="single-option">
                      <Link to={"/signup"}>
                        Sign Up <img src={chevronRight} />
                      </Link>
                    </div>
                    <div className="single-option get-started">
                      <Link to={"#"} className="getting-started">
                        Getting Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <h1>To Our Platform</h1> */}
            <div className="page-desciption">
              
              <div className="page-title">
                
                
              </div>
              <p>
                Take the guesswork out of learning with built-in tools that help
              </p>
              <div className="login-options">
                <div className="single-option get-started">
                  <Link to={"#"} className="getting-started">
                    Getting Started
                  </Link>
                </div>
                <div className="single-option">
                  <Link to={"/signup"}>
                    Sign Up <img src={chevronRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-cards">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="single-card">
                    <Link to={"#"}>
                      <div className="icon">
                        <img src={login} alt="icon" />
                      </div>
                      <span>Login</span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="single-card">
                    <Link to={"#"}>
                      <div className="icon">
                        <img src={docs} alt="icon" />
                      </div>
                      <span>Docs</span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="single-card">
                    <Link to={"#"}>
                      <div className="icon">
                        <img src={wifi} alt="icon" />
                      </div>
                      <span>Blog</span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="single-card">
                    <Link to={"/contact-us"}>
                      <div className="icon">
                        <img src={contact} alt="icon" />
                      </div>
                      <span>Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-banner">
              <img src={homeBanner} alt="banner" className="light-theme-img" />
              <img
                src={homeBannerDark}
                alt="banner"
                className="dark-theme-img mt-5"
              />
            </div>
          </div>
        </div>
        {/* <div className="page-banner">
  <img src={homeBanner} alt="banner" />
</div> */}
      </div>
      <SecondaryFooter />
    </>
  );
}
