import expandIcon from "../images/icons/expand-icon.svg";
import {Link} from "react-router-dom"
import { useState } from "react";


export default function LegalSidebar() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`legal-details-sidebar ${isOpen ? "open" : "closed"}`}>
        
      <ul>
        <li>
          <Link to={"#"}>Terms of Service</Link>
        </li>
        <li>
          <Link to={"#"}>Privacy Policy</Link>
        </li>
        <li className="active">
          <Link to={"#"}>COPPA</Link>
        </li>
        <li>
          <Link to={"#"}>DPA</Link>
        </li>
        <li>
          <Link to={"#"}>GDPR</Link>
        </li>
        <li>
          <Link to={"#"}>CCPA</Link>
        </li>
        <li>
          <Link to={"#"}>PCI DSS</Link>
        </li>
        <li>
          <Link to={"#"}>ADA Compliance</Link>
        </li>
        <li>
          <Link to={"#"}>DMCA</Link>
        </li>
      </ul>
      <div className="expand-icon">
        <button onClick={toggleSidebar}>
          <img src={expandIcon} alt="Expand Icon" />
        </button>
      </div>
    </div>
  );
}
