import HeaderComponent from "../components/HeaderComponent";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import checkGreen from "../images/check-green.svg"
export default function ThankYouPage(){
    return(
        <div className="thank-you-page-wrapper">
            <SecondaryHeader />
            <div className="page-content-wrapper">
                <div className="container-fluid p-0">
                    <div className="page-content">
                        <div className="page-inner">
                            <div className="check-icon">
                                <img src={checkGreen} alt="check icon" />
                            </div>
                            <div className="content-section">
                                <div className="title">
                                    <h1><span>Thank You : <br /></span>
                                    We’ll be in touch soon</h1>
                                </div>
                                <p>Learning Nexus is a dynamic platform designed to revolutionize the way individuals engage with learning.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter />
        </div>
    )
}