import SecondaryHeader from "../components/SecondaryHeader";
import logo from "../images/icons/logo.svg";
import adminPanel from "../images/icons/admin-panel.svg";
import legalDocs from "../images/icons/legal-docs.svg";
import terms from "../images/icons/terms.svg";
import aiUsage from "../images/icons/ai-usage.svg";
import acceptableUsage from "../images/icons/acceptable-usage.svg";
import dataProtection from "../images/icons/data-protection.svg";
import ilmiyaMark from "../images/icons/ilmiya-mark.svg";
import parentalLock from "../images/icons/parental-lock.svg";
import privacyDisclosure from "../images/icons/privacy-disclosure.svg";
import studentPrivacy from "../images/icons/student-privacy.svg";
import subProcess from "../images/icons/sub-process.svg";

import privacy from "../images/icons/privacy.svg";
import { Link } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
const tableItems = [
  {
    id: 1,
    icon: terms,
    title: "Terms of Service",
    route:"https://legal.ilmiya.com/p/KdXuVooqb8Z1BE/Terms-of-Service"
  },
  {
    id: 2,
    icon: privacy,
    title: "Privacy Policy",
    route:"https://legal.ilmiya.com/p/Q_NY3B88JWXzpS/Privacy-Policy"
  },
  {
    id: 3,
    icon: acceptableUsage,
    title: "Acceptable Usage Policy",
    route:"https://legal.ilmiya.com/p/84FkwMNn1Z6GnL/Acceptable-Usage-Policy"

  },
  {
    id: 4,
    icon: dataProtection,
    title: "Data Protection Addendum",
    route:"https://legal.ilmiya.com/p/gTkqn236efEj8D/Data-Protection-Addendum"

  },
  {
    id: 5,
    icon: privacyDisclosure,
    title: "Privacy Disclosure for Children (COPPA)",
    route:"https://legal.ilmiya.com/p/k7OD84qP6wrZ3g/Privacy-Disclosure-for-Children-COPPA"

  },
  {
    id: 6,
    icon: parentalLock,
    title: "Parental Consent",
    route:"https://legal.ilmiya.com/p/9US_kvgD8WINWW/Parental-Consent"

  },
  {
    id: 7,
    icon: studentPrivacy,
    title: "Student Privacy Pledge",
    route:"https://legal.ilmiya.com/p/_PpT8InWun0j5S/Student-Privacy-Pledge"

  },
  {
    id: 8,
    icon: ilmiyaMark,
    title: "Ilmiya's Mark Usage Terms",
    route:"https://legal.ilmiya.com/p/R2_mN8Ocwd74Oe/Ilmiya-s-Mark-Usage-Terms"

  },
  {
    id: 9,
    icon: aiUsage,
    title: "AI Usage Guidelines",
    route:"https://legal.ilmiya.com/p/KlFer3jzTlMIFc/AI-Usage-Guidelines"

  },
  {
    id: 10,
    icon: subProcess,
    title: "Ilmiya Sub-Processors List",
    route:"https://legal.ilmiya.com/p/zrs_8z0y8sBGsP/Ilmiya-Sub-Processors-List"

  },
];
export default function LegalPage() {
  return (
    <div className="legal-page-wrapper">
      <SecondaryHeader />
      <main>
        <div className="legal-page-content-wrapper">
          <div className="container">
            <div className="page-title">
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
              <strong>Ilmiya Legal Center</strong>
            </div>
            <div className="table-title">
              <div className="icon">
                <img src={adminPanel} alt="icon" />
              </div>
              <strong>Ensuring Trust and Transparency in Every Step</strong>
            </div>
            <div className="listing-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="docs">Docs</th>
                  </tr>
                </thead>
                <tbody>
                  {tableItems.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div className="item-name">
                          <div className="icon">
                            <img src={item.icon} alt="icon" />
                          </div>
                          <Link to={item.route}>{item.title}</Link>
                        </div>
                      </td>
                      <td className="docs">
                        <img src={legalDocs} alt="" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
