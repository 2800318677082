import adminIcon from "../images/icons/admin-privacy.png"
import uploadIcon from "../images/icons/upload-icon.svg";

export default function LegalTopbar() {
  return (
    <div className="legal-topbar">
      <div className="sidebar-title">
        <div className="icon">
          <img src={adminIcon} alt="icon" />
        </div>
        <span>Legal</span>
      </div>
      <div className="title">
        <h2>Privacy Policy</h2>
      </div>
      <div className="upload-icon">
        <img src={uploadIcon} alt="upload-icon" />
      </div>
    </div>
  );
}
