import Dropdown from "react-bootstrap/Dropdown";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import docs from "../images/icons/docs.png";
import policy from "../images/icons/policy.png";
import logo from "../images/icons/logo.svg"
import apps from "../images/icons/apps.svg"
import bars from "../images/icons/bar.svg"
import darkMode from "../images/icons/dark_mode.svg"
import lightMode from "../images/icons/light-mode.svg"

export default function SecondaryHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [state, setState] = useState("");

  const showMenuHandler = () => {
    setShowMenu(true);
  };
  const closeMenuHandler = () => {
    setShowMenu(false);
  };
  const handleTheme = () => {
    if (state === "dark") {
      setState("light");
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.add("dark-theme");
      setState("dark");
      localStorage.setItem("theme", "dark");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.add("dark-theme");
      setState("dark");
    }
  }, []);

  return (
    <header className="header">
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-md-2 col-2 align-self-center">
            <div className="logo-wrapper">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" />{" "}
              </Link>
              <a to="#" className="hamburger">
                <img
                  src={require("../images/icons/hamburger-icon.svg")}
                  alt="hamburgerIcon"
                  onClick={showMenuHandler}
                />
              </a>
            </div>
          </div>
          <div className="col-md-10 col-10 align-self-center">
            <div className={`menu-wrapper ${showMenu ? "active" : ""}`}>
              <RxCross2
                className="menu-close-icon"
                onClick={closeMenuHandler}
              />
              <ul className="menu">
                <li className="bordered">
                  <img src={apps} alt="apps" />
                  <div className="header-modal">
                    <ul>
                    <li>
                        <Link to="/signup">
                          <img src={docs} alt="contract icon" /> Sign Up
                        </Link>
                      </li>
                      <li>
                        <Link to="/about-us">
                          <img src={docs} alt="contract icon" /> About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/legal">
                          <img src={policy} alt="contract icon" /> Legal
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <img src={policy} alt="contract icon" /> Contact 
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li >
                  <img src={bars} alt="bar" />
                </li>
                <li className="bordered">
                  <img
                    onClick={handleTheme}
                    src={darkMode}
                    alt="dark"
                    className='light-theme-img'
                  />
                  <img
                    onClick={handleTheme}
                    src={lightMode}
                    alt="dark"
                    className='dark-theme-img'
                  />
                </li>
                <li>
                  <Link to="https://accounts.ilmiya.com/" className="btn-style text">
                    Login
                  </Link>
                </li>

                
              </ul>
              <div className="request-demo-btn">
                <Link
                  to="https://accounts.ilmiya.com/"
                  className="btn-style text"
                  target="_blank"
                >
                  Request demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container large position-relative d-none d-none mobile-view">
        <div className="row">
          <div className="col-md-12 align-self-center">
            <div className="logo-wrapper">
              <img
                src={logo}
                alt="logo"
                className="logo"
              />
              <RxHamburgerMenu
                className="hamburger"
                onClick={showMenuHandler}
              />
              <div className={`left-menu ${showMenu ? "d-block" : "d-none"}`}>
                <ul className="menu">
                  <li>
                    <a to="#">Legal</a>
                  </li>
                  <li>
                    <a to="#">View demo</a>
                  </li>
                  <li>
                    <a to="#" className="btn-style">
                      Start free trial
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
